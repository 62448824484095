(function () {
  'use strict';

  angular
    .module('neo.home.championships.newChampionship')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.championships.newChampionship', {
        url: '/new-championship',
        templateUrl: 'home/championships/new-championship/new-championship.tpl.html',
        controller: 'NewChampionshipCtrl',
        controllerAs: 'vm',
        resolve: {
          acl: function ($q, AclService) {
            return (AclService.can('LOCAL_PHASE', 'C')) ? true : $q.reject('Unauthorized');
          }
        }
      });
  }
}());
